import React from "react"

import Seo from "../components/seo"
import Layout from "../components/layout"

const TermsAndConditionsPage = () => {
  return (
    <Layout>
      <Seo
        title="Terms & Conditions"
        description="Remarketing Plus Terms & Conditions"
      />

      <div className="px-ogs">
        <div className="grid grid-cols-12 gap-x-gs gap-y-12 py-12">
          <div className="col-span-12 md:col-span-4">
            <h3 className="text-xl lg:text-3xl font-extralight leading-none tracking-tight-3">
              Terms &amp; Consitions
            </h3>
          </div>
          <div className="col-span-12 md:col-span-8">
            <div className="dsihtml">
              <h3>1. Terms</h3>
              <p>
                By accessing this web site, you are agreeing to be bound by
                these web site Terms and Conditions of Use, all applicable laws
                and regulations, and agree that you are responsible for
                compliance with any applicable local laws. If you do not agree
                with any of these terms, you are prohibited from using or
                accessing this site. The materials contained in this web site
                are protected by applicable copyright and trade mark law.
              </p>
              <h3>2. License</h3>
              <p>
                Permission is granted to temporarily download one copy of the
                materials (information or software) on Remarketing Plus web site
                for personal, non-commercial transitory viewing only. This is
                the grant of a license, not a transfer of title, and under this
                license you may not: modify or copy the materials; use the
                materials for any commercial purpose, or for any public display
                (commercial or non-commercial); attempt to decompile or reverse
                engineer any software contained on Remarketing Plus web site;
                remove any copyright or other proprietary notations from the
                materials; or transfer the materials to another person or
                “mirror” the materials on any other server. This license shall
                automatically terminate if you violate any of these restrictions
                and may be terminated by Lender Compliance Technologies at any
                time. Upon terminating your viewing of these materials or upon
                the termination of this license, you must destroy any downloaded
                materials in your possession whether in electronic or printed
                format.
              </p>
              <h3>3. Disclaimer</h3>
              <p>
                The materials on Remarketing Plus web site are provided “as is”.
                Remarketing Plus makes no warranties, expressed or implied, and
                hereby disclaims and negates all other warranties, including
                without limitation, implied warranties or conditions of
                merchantability, fitness for a particular purpose, or
                non-infringement of intellectual property or other violation of
                rights. Further, Lender Compliance Technologies does not warrant
                or make any representations concerning the accuracy, likely
                results, or reliability of the use of the materials on its
                Internet web site or otherwise relating to such materials or on
                any sites linked to this site.
              </p>
              <h3>4. Limitations</h3>
              <p>
                In no event shall Remarketing Plus or its suppliers be liable
                for any damages (including, without limitation, damages for loss
                of data or profit, or due to business interruption,) arising out
                of the use or inability to use the materials on Lender
                Compliance Technologies’s Internet site, even if Remarketing
                Plus or a Remarketing Plus authorized representative has been
                notified orally or in writing of the possibility of such damage.
                Because some jurisdictions do not allow limitations on implied
                warranties, or limitations of liability for consequential or
                incidental damages, these limitations may not apply to you.
              </p>
              <h3>5. Revisions of Errata</h3>
              <p>
                The materials appearing on Remarketing Plus web site could
                include technical, typographical, or photographic errors. Lender
                Compliance Technologies does not warrant that any of the
                materials on its web site are accurate, complete, or current.
                Lender Compliance Technologies may make changes to the materials
                contained on its web site at any time without notice.
                Remarketing Plus does not, however, make any commitment to
                update the materials.
              </p>
              <h3>6. Links</h3>
              <p>
                Remarketing Plus has not reviewed all of the sites linked to its
                Internet web site and is not responsible for the contents of any
                such linked site. The inclusion of any link does not imply
                endorsement by Remarketing Plus of the site. Use of any such
                linked web site is at the user’s own risk.
              </p>
              <h3>7. Site terms of use modifications</h3>
              <p>
                Remarketing Plus may revise these terms of use for its web site
                at any time without notice. By using this web site you are
                agreeing to be bound by the then current version of these Terms
                and Conditions of Use.
              </p>
              <h3>8. Governing Laws</h3>
              <p>
                Any claim relating to Remarketing Plus web site shall be
                governed by the laws of the State of California without regard
                to its conflict of law provisions. General Terms and Conditions
                applicable to Use of a Web Site.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default TermsAndConditionsPage
